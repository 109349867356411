import { $, on } from "@olmokit/dom";
import gsap from "gsap";
import CSSPlugin from "gsap/CSSPlugin";
import "./mobile.scss";

// this is needed in order to avoid that tree shaking would break gsap on build
gsap.registerPlugin(CSSPlugin);

export default function MenuMobile() {

  console.log('MenuMobile mounted');

  let hasInit = false;
  let isPlaying = false;
  let lastZIndex = 4;
  let screenTo = "";
  let timelineMain;
  let timelineSolutions;
  const animStripeFrom = { scaleX: 0, transformOrigin: "left" };
  const animTextFrom = { opacity: 0, xPercent: -20 };

  /**
   * Init constructing main timeline and binding event listeners.
   * Putting this in a funciton we can defer the initialization of the menu.
   */
  function init() {
    if (hasInit) {
      return;
    }
    setInitialState();
    setEffects();
    bindListeners();
    timelineMain = getMain();
    timelineSolutions = getSolutions();
    hasInit = true;
  }

  function setEffects() {
    gsap.registerEffect({
      name: "$tex",
      effect: (targets, config) => {
        return gsap.to(targets, {
          duration: config.duration,
          opacity: 1,
          xPercent: 0
        });
      },
      defaults: { duration: 0.4 },
      extendTimeline: true
    });

    gsap.registerEffect({
      name: "$str",
      effect: (targets, config) => {
        return gsap.to(targets, {
          duration: config.duration,
          scaleX: 1
        });
      },
      defaults: { duration: 0.4 },
      extendTimeline: true
    });
  }

  /**
   * Hide all elements as initial state, which are CSSed in the screens
   * overlapped so they will be in the right position according to the viewport.
   */
  function setInitialState() {
    const stripeAttrs = animStripeFrom;
    const textAttrs = animTextFrom;

    gsap.set("#M-wrap-screens", { scale: 1, opacity: 1 });
    gsap.set(".M-text", textAttrs);
    gsap.set(".M-stripe", stripeAttrs);
  }

  /**
   * Bind event listeners
   */
  function bindListeners() {
    on($("#M-main-solutions"), "click", goToSolutions);
    on($("#M-solutions-back"), "click", goBackToMain);
  }

  /**
   * Construct timeline: initial screen
   */
  function getMain(name = "main") {
    return (
      gsap
        .timeline({
          paused: true,
          defaults: { duration: 0.4 },
          onStart: () => {
            bringScreenToFront(name);
          }
        })
        // restore inner menu position
        .set("#M-main .M-inner", { opacity: 1, xPercent: 0 })
        .set("#M-footer", { opacity: 1, xPercent: 0, display: "block" })
        .$str("#M-main-company .M-stripe")
        .$tex("#M-main-company .M-text")
        .$str("#M-main-solutions .M-stripe")
        .$tex("#M-main-solutions .M-text")
        .$str("#M-main-services .M-stripe")
        .$tex("#M-main-services .M-text")
        .$str("#M-main-gear .M-stripe")
        .$tex("#M-main-gear .M-text")
        .$str("#M-main-contacts .M-stripe")
        .$tex("#M-main-contacts .M-text")
        .$str("#M-locale .M-stripe")
        .$tex("#M-locale .M-text")
    );
  }

  /**
   * Construct timeline: solutions submenu
   */
  function getSolutions(name = "solutions") {
    return (
      gsap
        .timeline({
          paused: true,
          defaults: { duration: 0.4 },
          onStart: () => {
            bringScreenToFront(name);
          },
          onReverseComplete: () => {
            bringScreenToFront(screenTo);
          }
        })
        // restore inner menu position
        .set("#M-solutions .M-inner", { opacity: 1, xPercent: 0 })
        .set("#M-main-solutions", { opacity: 0 })
        // fade to the left the main screen
        .to("#M-main .M-inner", { opacity: 0, xPercent: -100 })
        .to("#M-footer", { opacity: 0, xPercent: -100 }, "<")
        .set("#M-footer", { display: "none" })
        .$tex("#M-solutions-back .M-text")
        .$tex("#M-solutions-solutions .M-text")
        // animate links and submenus now
        .$str("#M-solutions-automotive .M-stripe")
        .$tex("#M-solutions-automotive .M-text.M-title")
        .to("#M-solutions-automotive .M-text.M-sub", {
          opacity: 1,
          xPercent: 0,
          stagger: 0.2
        })
        .$str("#M-solutions-foundry .M-stripe")
        .$tex("#M-solutions-foundry .M-text")
    );
  }
  /**
   * As screens are all absolutely positioned we need to bring to the front the
   * given one so that other's screen elements will not be clickable.
   *
   * @param {string} screenName
   */
  function bringScreenToFront(screenName) {
    lastZIndex++;
    gsap.set(`#M-${screenName}`, { zIndex: lastZIndex });
  }

  /**
   * Go back to main menu
   */
  function goBackToMain() {
    screenTo = "main";

    timelineSolutions.timeScale(1.8).reverse();
  }

  /**
   * Open solutions submenu
   */
  function goToSolutions() {
    screenTo = "solutions";

    timelineSolutions.timeScale(1).restart();
  }

  /**
   * Play the main menu opening animation
   */
  function play() {
    isPlaying = true;
    screenTo = "main";
    gsap.set("#M-wrap-content", { display: "flex", scale: 1, opacity: 1 });
    timelineMain.timeScale(1).restart();
  }

  /**
   * Reset all timelines
   */
  function reset(callback) {
    screenTo = "main";
    isPlaying = false;
    gsap.to("#M-wrap-content", {
      scale: 0.8,
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        timelineMain.timeScale(10).reverse();
        timelineSolutions.timeScale(10).reverse();
        setInitialState();
        gsap.set("#M-wrap-content", { display: "none" });

        if (callback) callback();
      }
    });
  }

  /**
   * Destroy
   */
  function destroy() {
    if (!hasInit) return;

    hasInit = false;
    reset();
    // setInitialState();

    off($("#M-main-solutions"), "click", goToSolutions);
    off($("#M-solutions-back"), "click", goBackToMain);
  }

  return {
    hasInit,
    isPlaying,
    init,
    play,
    reset,
    destroy
  };
}
