/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import { Newsletter } from "components/Newsletter";
import { Scene } from "components/Scene";
import { SliderGear } from "components/SliderGear";
import { glide, Autoplay, LazyLoad, Swipe } from "@olmokit/core/glide";

console.log("Route home/index.js mounted.");

glide(".intro:slider", { autoplay: 4000 }).mount({ Autoplay, LazyLoad, Swipe });

Newsletter();
Scene();
SliderGear();