import "components/Hamburger";
import { $, on } from "@olmokit/dom";
import scrollTo from "@olmokit/core/scroll/scrollTo";
import scrollLock, { fillGapsOf } from "@olmokit/core/scroll/lock";
import gsap from "gsap";
import CSSPlugin from "gsap/CSSPlugin";
import { I18nLinks } from "@olmokit/core/i18n/links";
import menu from "components/Menu";
import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {
  I18nLinks();

  // this is needed in order to avoid that tree shaking would break gsap on build
  gsap.registerPlugin(CSSPlugin);

  // constants
  const CLASS_HAMBURGER_ACTIVE = "is-active";

  // dom
  const $hamburger = $(".Hamburger:");
  const $menuBg = $("#M-wrap-bg");
  const $menuContent = $("#M-wrap-content");
  const $logoText = $("#logo-text-negative");
  const $logoPayoff = $("#logo-payoff-negative");

  // state
  let isOpen = false;

  // others
  const timeline = gsap
    .timeline({
      paused: true,
      defaults: { duration: 0.4 },
      onComplete: () => {
        // FIXME: this is to prevent play to be called twice, for some reasons
        // onComplete is fired twice
        if (!isOpen) {
          menu.play();
          isOpen = true;
        }
      },
      onReverseComplete: () => {
        isOpen = false;
      },
    })
    .fromTo($menuBg, { display: "block", xPercent: -100 }, { xPercent: 0 })
    .to($logoText, { attr: { width: 583 } }, "<")
    .to($logoPayoff, { attr: { width: 583 } }, "<");

  // prepare scroll lock
  fillGapsOf([$(".Header:")]);

  // bind listeners
  on($hamburger, "click", () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  });

  function open() {
    scrollTo(0, {
      onstop: () => {
        $hamburger.classList.add(CLASS_HAMBURGER_ACTIVE);
        menu.init();
        scrollLock.disable($menuContent);
        timeline.play();
      },
    });
  }

  function close() {
    // timeline.pause();
    menu.reset();
    timeline.reverse();
    scrollLock.enable($menuContent);
    $hamburger.classList.remove(CLASS_HAMBURGER_ACTIVE);
  }

}