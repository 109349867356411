import "./index.scss";
import Olmoforms from "@olmokit/core/olmoforms/feedback";
import "@olmokit/core/forms/input/filled";
import "@olmokit/core/forms/checkbox";
// import { $ } from "@olmokit/dom";

/**
 * Component: Newsletter
 */
export function Newsletter() {
  console.log("Newsletter mounted.");

  const olmoforms = Olmoforms(".Newsletter:", {
    sent: (formData) => {}, 
    failed: (instance) => {}
  });  

}
