import "@olmokit/core/responsive/only";
import responsiveComponent from "@olmokit/core/responsive/component";
import { breakpoints } from "@olmokit/core/scss";
import mobile from "./mobile";
import desktop from "./desktop";
import "./index.scss";

/**
 * Component: Menu
 */
  
console.log("Menu mounted."); 

const Menu = responsiveComponent("md", {
  breakpoints,
  mobile: mobile(),
  desktop: desktop()
});  

export default Menu;