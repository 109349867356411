import { $, $all, forEach, listenResize } from "@olmokit/dom";
import { detect, hasTouch } from "@olmokit/core/detect";
import { breakpoints } from "@olmokit/core/scss";
import {
  glide,
  Controls,
  AutoHeight,
  Breakpoints,
  LazyLoad,
  Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/bullets";
import "./index.scss";

detect(hasTouch);

const $sliders = $all(".Slider: .glide");

/**
 * Get current sldier mode
 *
 * @returns {"desktop" | "mobile"}
 */
function getCurrentMode() {
  return window.innerWidth > breakpoints.xl ? "desktop" : "mobile";
}

/**
 * Init slider
 *
 * @param {HTMLElement} $el
 * @param {number} slidesQuantity
 * @param {"desktop" | "mobile"} mode
 */
function initSlider($el, slidesQuantity, mode) {
  const swipeable =
    mode === "desktop" ? slidesQuantity > 3 : slidesQuantity >= 1;
  const loop = mode === "desktop" ? slidesQuantity > 3 : slidesQuantity >= 1;
  const components = { Breakpoints, Controls, LazyLoad, Swipe };
  const options = {
    loop,
    swipeable,
    autoHeight: true,
    perView: 3,
    perTouch: 1,
    breakpoints: {
      [breakpoints.xl]: {
        perView: 2.5,
      },
      [breakpoints.lg]: {
        perView: 2,
      },
      [breakpoints.md]: {
        perView: 1.5,
      },
      [breakpoints.sm]: {
        perView: 1,
      },
    },
  };

  if (mode === "mobile" && slidesQuantity >= 2) {
    components.AutoHeight = AutoHeight;
  }

  const $arrows = $(".glide__arrows", $el);
  const $bullets = $(".glide__bullets", $el);

  if (mode === "desktop" && slidesQuantity <= 3) {
    $arrows.style.display = "none";
    $bullets.style.display = "none";
  } else {
    $arrows.style.display = "inherit";
    $bullets.style.display = "inherit";
  }

  return glide($el, options).mount(components);
}

forEach($sliders, ($slider) => {
  const slidesQuantity = $all(".glide__slide", $slider).length;

  if (slidesQuantity === 1) {
    return;
  }

  let lastMode = getCurrentMode();
  let instance = initSlider($slider, slidesQuantity, lastMode);

  listenResize(() => {
    const newMode = getCurrentMode();
    if (lastMode !== newMode) {
      if (instance) {
        instance.destroy();
      }
      instance = initSlider($slider, slidesQuantity, newMode);
      lastMode = newMode;
    }
  });
});
