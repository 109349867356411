import "./index.scss";
// import { $ } from "@olmokit/dom";

/**
 * This file is compiled separately from the main js entryfile and it is included
 * inlined *only* in the html of the template `pages/home.htm`.
 * In this way the scripts written in this file will always remain scoped to the
 * page, helping to avoid unintended side effects and reducing the requested
 * shared bundle size. This file serves also as the main entry point for the
 * page `home.htm`, therefore not only scripts but also styles
 * specific to this page should be imported here. All the imported `vendors`
 * files will be grouped in a single file included in every page, so you can
 * import mulitple times the same `node_module` across various pages being sure
 * that they will not be duplicated.
 * (Don't remove this comment, all comments will be stripped out in production.)
 */
import { forEach, $, $all, on } from "@olmokit/dom";
import {
  glide,
  Autoplay,
  Controls,
  LazyLoad,
  CrossFade,
  Swipe,
} from "@olmokit/core/glide";
import gsap from "gsap";
import CSSPlugin from "gsap/CSSPlugin";
import "components/ScrollInvite";
import "./index.scss";

/**
 * Component: Scene
 */
export function Scene() {
  console.log("Scene mounted.");

  // this is needed in order to avoid that tree shaking would break gsap on build
  gsap.registerPlugin(CSSPlugin);

  const SLIDE_FADE_DURATION = 800;
  const CLASS_BULLET_VISIBLE = "visible";
  const CLASS_BULLET_CURRENT = "current";
  const CLASS_DOT_OPEN = "open";
  const CLASS_DOT_HIGHLIGHT = "highlight";

  // DOM
  const $bullets = $all(".Scene:bullet");
  const $dots = $all(".Scene:dot");
  const $cards = $all(".Scene:card");
  const $cardWrap = $(".Scene:cardWrap");
  const $cardInner = $(".Scene:cardInner");
  const $cardClose = $(".Scene:cardWrap .close");
  const $arrows = $(".Scene: .glide__arrows");

  // state
  let areCardsOpen = false;
  let isPlaying = true;
  let isMouseOnPauseArea = false;
  let hoverStateChecker = null;

  // products map by id containing the needed DOM elements
  const productsMap = {};
  let firstProductId = "";

  // slides map by index as used by glide
  const slidesMap = {};

  const sliderOptions = {
    loop: true,
    animationDuration: SLIDE_FADE_DURATION,
    autoplay: 3000,
    hoverpause: false, // otherwise it would be always in pause...
  };

  const containerSlider = $(".Scene:");

  // init imgs slider
  const slider = glide(containerSlider, sliderOptions);  

  slider.on("run", () => {
      setBulletsVisibility(slider.index);
      setBulletsCurrent(null);
      closeAllCards();
      // here we might highlight all dots if bullet was not clicked but we got here
      // instead with auotplaying
    })
    .on("play", () => {
      isPlaying = true;
    })
    .on("pause", () => {
      isPlaying = false;
    });

  // add product bullets to products and slides map, use bullets because they
  // are in the correct order
  forEach($bullets, ($bullet) => {
    const productId = $bullet.getAttribute("data-product");
    const slideIdx = extractSlideIdx($bullet);
    firstProductId = firstProductId || productId;
    productsMap[productId] = { $bullet };

    const slide = slidesMap[slideIdx] || {};
    // slide.$bullets ? slide.$bullets.push($bullet) : slide.$bullets = [$bullet];
    slide.productIds
      ? slide.productIds.push(productId)
      : (slide.productIds = [productId]);
    slidesMap[slideIdx] = slide;

    on($bullet, "mouseenter", () => {
      if (slideIdx === slider.index) {
        isMouseOnPauseArea = true;
        clearTimeout(hoverStateChecker);
        slider.pause();
      } else {
        isMouseOnPauseArea = false;
        maybeResumeAutoplay();
      }
    });

    on($bullet, "mouseleave", () => {
      isMouseOnPauseArea = false;
      maybeResumeAutoplay();
    });
  });

  slider.mount({ Autoplay, CrossFade, Controls, LazyLoad, Swipe });

  // add product dots to products map in an array, same product can have multiple
  // dots on the same slide
  forEach($dots, ($dot) => {
    const id = $dot.getAttribute("data-product");
    const product = productsMap[id];
    product.$dots = product.$dots || [];
    product.$dots.push($dot);

    on($dot, "mouseenter", () => {
      isMouseOnPauseArea = true;
      clearTimeout(hoverStateChecker);
      slider.pause();
    });

    on($dot, "mouseleave", () => {
      isMouseOnPauseArea = false;
      maybeResumeAutoplay();
    });
  });

  // Slider in pause when cursor is on the arrows
  on($arrows, "mouseenter", () => {
    clearTimeout(hoverStateChecker);
    slider.pause();
  });

  on($arrows, "mouseleave", () => {
    maybeResumeAutoplay();
  });

  // add product cards to products map
  forEach($cards, ($card) => {
    const id = $card.getAttribute("data-product");
    const product = productsMap[id];
    product.$card = $card;
  });

  // set bullets state immediately, the ones in the first slide are visible...
  setBulletsVisibility(0);  

  // bind bullets to lead to their slide on click
  forEach($bullets, ($bullet) => {
    const slideIdx = extractSlideIdx($bullet);
    const productId = $bullet.getAttribute("data-product");

    on($bullet, "click", () => {
      slider.go(`=${slideIdx}`);
      highlightDot(productId);
    });
  });

  // bind slide dots to open their product card
  forEach($dots, ($dot) => {
    const productId = $dot.getAttribute("data-product");

    on($dot, "click", () => {
      toggleCard(productId, $dot);
    });
  });

  // bind the card's close button
  on($cardClose, "click", () => {
    closeAllCards();
    setBulletsCurrent(null);
  });  

  /**
   * Maybe resume autoplay after the given amount of waiting
   *
   * @param {number} [delay]
   */
  function maybeResumeAutoplay(delay = 2500) {
    hoverStateChecker = setTimeout(() => {
      if (!isMouseOnPauseArea && !isPlaying && !areCardsOpen) {
        // slider.go(">");
        slider.play();
      }
    }, delay);
  }

  /**
   * Read the `data-slide` of the given node and parses it as the correct slide
   * index integer in use by `glide.js`
   *
   * @param {HTMLElement} $element
   */
  function extractSlideIdx($element) {
    const slide = $element.getAttribute("data-slide");
    return parseInt(slide, 10) - 1;
  }

  /**
   * Highlights temporarily the current product dot
   * @param {string} productId
   */
  function highlightDot(productId) {
    const { $dots } = productsMap[productId];

    forEach($dots, ($dot) => {
      setTimeout(() => {
        $dot.classList.add(CLASS_DOT_HIGHLIGHT);
      }, SLIDE_FADE_DURATION);
      setTimeout(() => {
        $dot.classList.remove(CLASS_DOT_HIGHLIGHT);
      }, SLIDE_FADE_DURATION + 1000);
    });
  }

  /**
   * Set bullets visibility state, the same slide can have multiple visible bullets
   */
  function setBulletsVisibility(currentSlideIdx) {
    forEach($bullets, ($bullet) => {
      const slideIdx = $bullet.getAttribute("data-slide");
      if (parseInt(slideIdx, 10) === currentSlideIdx + 1) {
        $bullet.classList.add(CLASS_BULLET_VISIBLE);
      } else {
        $bullet.classList.remove(CLASS_BULLET_VISIBLE);
      }
    });
  }

  /**
   * Set bullets current state, only the one clicked gets this state, because
   * the same slide can have multiple visible bullets, but the one clicked gets
   * a more prominent highlight
   *
   * @param {string} productId
   */
  function setBulletsCurrent(currentProductId) {
    forEach($bullets, ($bullet) => {
      const productId = $bullet.getAttribute("data-product");
      if (productId === currentProductId) {
        $bullet.classList.add(CLASS_BULLET_CURRENT);
      } else {
        $bullet.classList.remove(CLASS_BULLET_CURRENT);
      }
    });
  }

  /**
   * Toggle product card
   *
   * @param {string} productId
   * @param {HTMLElement} $dot
   */
  function toggleCard(productId, $dot) {
    const product = productsMap[productId];
    if (product.isOpen) {
      handleCloseCard(productId);
      setBulletsCurrent(null);
    } else {
      handleOpenCard(productId, $dot);
      setBulletsCurrent(productId);
    }
  }

  /**
   * Handle open product card action
   *
   * @param {string} productId
   * @param {HTMLElement} $dot
   */
  function handleOpenCard(productId, $dot) {
    const product = productsMap[productId];
    const $cardContent = product.$card;
    if (product.isOpen) return;

    slider.pause();

    hideCardsContent();

    // different beahviour if we already have a card opened or not
    if (areCardsOpen) {
      // then show the new content but keep it invisible
      gsap.set($cardContent, { display: "block", opacity: 0 });

      const size = ideallySizeCard();
      const { width, height, top, left, side } = getCardPosition(size, $dot);

      $cardWrap.setAttribute("data-side", side);

      // then animate the height and the position all at once
      gsap.to($cardWrap, {
        top,
        left,
        width,
        height,
        duration: 0.5,
        onComplete: () => {
          // on complete fade in the content
          gsap.to($cardContent, { opacity: 1, duration: 0.4 });
        },
      });
    } else {
      // set inital state of the cardWrap
      gsap.set($cardWrap, { display: "block", opacity: 0, height: "auto" });

      // and toggle its content
      gsap.set($cardContent, { display: "block" });

      const size = ideallySizeCard();
      const { width, height, top, left, side } = getCardPosition(size, $dot);

      $cardWrap.setAttribute("data-size", side);

      // make card wrapper ready to expand
      gsap.set($cardWrap, { top, left, width, height, scale: 0.8, opacity: 0 });

      // now fade in the card inner while the parent reveals its card content
      gsap.to($cardInner, { opacity: 1, duration: 0.4 });
      gsap.to($cardWrap, { scale: 1, opacity: 1, duration: 0.4 });
    }

    product.isOpen = true;
    areCardsOpen = true;
    setTimeout(() => {
      product.$dots.forEach(($dot) => $dot.classList.add(CLASS_DOT_OPEN));
    }, 5);
  }

  /**
   * Update card dimensions, use the cardInner "natural" size and set it on it
   * to keep the content flow fixed. Return the "natural" dimensions
   */
  function ideallySizeCard() {
    const width = $cardInner.offsetWidth;
    const height = $cardInner.offsetHeight;

    // set the height of the inner so that its content does not reflow
    gsap.set($cardInner, { width, height });

    return { width, height };
  }

  /**
   * Get card position (top, left, side) and size (width, height) according to the
   * viewport and the clicked $dot position.
   *
   * @param {Object} idealSize The "natural" size of the card
   * @param {HTMLElement} $dot
   */
  function getCardPosition(idealSize, $dot) {
    const GUTTER = 60; // window.innerWidth > breakpoints.md ? 60 : 15;
    let side;
    let width;
    let height;
    let { top, left } = $dot.getBoundingClientRect();
    const dotLeftPercent = parseFloat($dot.getAttribute("data-left"));
    const idealHeight = idealSize.height;
    const idealWidth = idealSize.width;
    const winHeight = window.innerHeight;
    const winWidth = window.innerWidth;

    height = Math.min(idealHeight, winHeight);
    width = Math.min(idealWidth, winWidth);

    top = Math.max(GUTTER, top - height / 2);

    // in this case it preferable to put the card on the right side
    if (dotLeftPercent >= 50) {
      side = "right";
      left = Math.max(GUTTER, left - GUTTER - width);
      // otherwise put it on the right side being careful to don't go out of viewport
    } else {
      side = "left";
      left = left + GUTTER;
      if (left + width > winWidth) {
        left = left - (winWidth - (left + width));
      }
    }

    return { width, height, top, left, side };
  }

  /**
   * Handle close product card action
   *
   * @param {string} productId
   */
  function handleCloseCard(productId) {
    gsap.to($cardWrap, {
      scale: 0.8,
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        gsap.set($cardWrap, { display: "none" });

        onProductCardClose(productId);
      },
    });

    areCardsOpen = false;
    maybeResumeAutoplay();
  }

  /**
   * Close all product cards
   */
  function closeAllCards() {
    gsap.to($cardWrap, {
      scale: 0.8,
      opacity: 0,
      duration: 0.4,
      onComplete: () => {
        gsap.set($cardWrap, { display: "none" });

        for (const productId in productsMap) {
          onProductCardClose(productId);
        }
      },
    });
    areCardsOpen = false;
  }

  /**
   * Hide all inner card's contents
   */
  function hideCardsContent() {
    const currentCardHeight = $cardWrap.offsetHeight;

    // set fixed height
    gsap.set($cardWrap, { height: currentCardHeight });

    // then hide immediately all inner contents
    for (const id in productsMap) {
      onProductCardClose(id);
    }
  }

  /**
   * Update state and ui for a closed product card
   *
   * @param {string} productId
   */
  function onProductCardClose(productId) {
    const product = productsMap[productId];

    gsap.set(product.$card, { display: "none" });
    product.isOpen = false;
    // defer this
    setTimeout(() => {
      product.$dots.forEach(($dot) => $dot.classList.remove(CLASS_DOT_OPEN));
    }, 3);
  }

}

